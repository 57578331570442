.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.media-body-content {
  line-height: 60px !important;
  height: 35px;
}
